@font-face {
  font-family: 'AvenueX';
  src: local('AvenueX'), url('./fonts/AvenueX.otf') format('opentype');
}

body {
  margin: 0;
  font-family: 'AvenueX';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(#515151, #3c3c3d);
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
