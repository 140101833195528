* {
  box-sizing: border-box;
}

html {
  background: #000;
  color: #eee;
  font-size: 16px;
  line-height: 24px;
}

body {
  margin: 0;
  font-family: "AvenueX", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#render {
  user-select: none;
  /* cursor: crosshair; */
}

.label {
  font-size: 12px;
  text-transform: uppercase;
  line-height: 16px;
  margin-bottom: 4px;
}
.controlButton {
  cursor: pointer;
}
.controlButton:hover {
  background: rgba(0, 0, 0, 0.2);
}
.active,
.active:hover {
  background: rgba(0, 0, 0, 0.5);
  cursor: default;
}

.hoverUnderline {
  text-decoration: none;
}
.hoverUnderline:hover {
  text-decoration: underline;
}

/* img { */
/*   display: block; */
/*   width: 100%; */
/* } */
canvas {
  display: block;
  touch-action: none;
}

.lighter {
  color: #aaa;
}

.spacer {
  width: 100%;
  height: 16px;
}
.hspacer {
  width: 100%;
  height: 8px;
}
.qspacer {
  width: 100%;
  height: 4px;
}

.layoutControls {
  transform: scale(1);
  transition: 0.05s linear;
  transform-origin: center;
}
.layoutControls:hover {
  transform: scale(1.2);
}

.layoutControls .layoutButton {
  transform: scale(1);
  transform-origin: center;
  transition: 0.2s linear;
  position: absolute;
  left: 0;
  bottom: 0;
}
.layoutControls .layoutButton:hover {
}
.layoutControls.loading .layoutButton {
  transform: scale(0);
  z-index: 8;
}
.showGrid #islandButton {
  transform: scale(0);
  z-index: 9;
}
.showIsland #gridButton {
  transform: scale(0);
  z-index: 9;
}

.initialGrow {
  transition: 0.66s linear;
  transform-origin: center;
  transform: scale(0);
}

.initialGrow.load {
  transform: scale(1);
}

.aboutLink {
  transition: 0.1s linear;
  opacity: 0;
}
.aboutLink.aboutLinkLoaded {
  opacity: 1;
}

img {
  display: block;
}

:root {
  --cursor-size: 36px;
}
#cursor {
  position: absolute;
  left: 0;
  top: 0;
  width: var(--cursor-size);
  height: var(--cursor-size);
  margin-left: calc(var(--cursor-size) / -2);
  margin-top: calc(var(--cursor-size) / -2);
  pointer-events: none;
  background-position: center center;
  background-size: cover;
  transform: translate(-100px, -100px);
  pointer-events: none;
}
#cursor {
  border-radius: 100%;
  background-image: none;
  background: white;
  /* border: solid 1px white; */
  mix-blend-mode: difference;
}
